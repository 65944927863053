import { useLocation, useNavigate } from "react-router-dom";

const useQueryParam = (paramName) => {
  const location = useLocation();
  const navigate = useNavigate();

  // Get the query parameter value
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get(paramName);

  // Function to set or update the query parameter
  const setQueryParam = (value) => {
    if (value == undefined) return removeQueryParam();
    queryParams.set(paramName, value);

    // Update the URL with the new query string
    navigate(
      {
        pathname: location.pathname,
        search: queryParams.toString(),
      },
      { replace: true },
    );
  };

  // Function to remove the query parameter
  const removeQueryParam = () => {
    queryParams.delete(paramName);

    // Update the URL without the deleted query parameter
    navigate(
      {
        pathname: location.pathname,
        search: queryParams.toString(),
      },
      { replace: true },
    );
  };

  return {
    [paramName]: paramValue,
    ["set" + paramName.charAt(0).toUpperCase() + paramName.slice(1)]:
      setQueryParam,
    removeQueryParam,
  };
};

export default useQueryParam;
