import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../../assets/search.svg";
import useQueryParam from "../../../utils/useQueryParam";
import Recommendations from "./Layout/Recommendations";
import SearchResult from "./Layout/SearchResult";

const arrowStyle = {
  margin: "0px 10px",
};
const InputPropsStyle = {
  sx: {
    fieldset: {
      borderRadius: 0,
      borderWidth: 0,
    },
    "&:focus-within fieldset, &:focus-visible fieldset": {
      border: "none!important",
    },
    input: {
      "&::placeholder": {
        color: "#5EC401 !important",
        fontSize: "14px",
        padding: "0px 0px 0px 10px",
        mixBlendMode: "normal",
        opacity: "0.87",
      },
    },
  },
};

const outlineButton = {
  backgroundColor: "#F2F3F2",
  color: "#53B175",
  textTransform: "none",
  padding: "8px",
  fontSize: "14px",
  fontWeight: "600",
  width: "100%",
  fontFamily: "quicksand",
  borderRadius: "14px",
  "&.MuiButtonBase-root:hover": {
    background: "#F2F3F2",
  },
  // position: "relative",
  border: "3px solid #53B175",
};

const Search = () => {
  const { navQueryState } = useLocation().state ?? {};
  const { search, setSearch } = useQueryParam("search");

  const query = search ?? "";
  const [tempQuery, setTempQuery] = useState(navQueryState ?? "");

  const searchInputRef = useRef(null);

  useEffect(() => {
    if (navQueryState) setSearch(navQueryState);
  }, [navQueryState]);

  // useEffect(() => {
  //   if (!localStorage.getItem("user")) navigate("/");
  // }, []);
  const [drawerOpenFrom, setDrawerOpenFrom] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown") {
      return;
    }

    setDrawerOpenFrom({ ...drawerOpenFrom, [anchor]: open });
  };

  useEffect(() => {
    // Focus the search field when the component mounts
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  return (
    <Box sx={{ marginBottom: "100px", overflow: "hidden", height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <Box sx={arrowStyle} onClick={() => history.go(-1)}>
          {/* <Link to="/"> */}
          {/* <Button > */}
          <ArrowBackRoundedIcon />
          {/* </Button> */}
          {/* </Link> */}
        </Box>

        <TextField
          size="small"
          placeholder="Search"
          //   onBlurCapture={ (e) =>{
          //     console.log("clicked ")
          //   }}
          variant="outlined"
          value={tempQuery}
          onChange={(e) => {
            setTempQuery(e.target.value);
          }}
          onKeyDown={(e) => e.key === "Enter" && setSearch(tempQuery)}
          sx={{
            backgroundColor: "#F0F1F2",
            borderRadius: "8px 8px 0px 0px",
            width: "100%",
            margin: "10px 20px 10px 0px",
            borderBottom: "1px solid #5EC401",
          }}
          InputProps={{
            ...InputPropsStyle,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setSearch(tempQuery)}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            sx: {
              color: "#5EC401",
              textTransform: "capitalize",
            },
          }}
          // value={searchquery}
          inputRef={searchInputRef} // Assign the ref to the TextField
        />

        <IconButton onClick={toggleDrawer("left", true)}>
          <Button sx={outlineButton}>Filter</Button>
        </IconButton>
      </Box>

      {!search && tempQuery && (
        <Recommendations tempQuery={tempQuery} setTempQuery={setTempQuery} />
      )}

      {/* <Discover />  */}
      <SearchResult
        query={query}
        setQuery={setSearch}
        drawerOpenFrom={drawerOpenFrom}
        setDrawerOpenFrom={setDrawerOpenFrom}
      />
      {/* <ProductNotFound /> */}
    </Box>
  );
};

export default Search;
