import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import server from "../../../Components/server";

export const QuickCommerceContext = createContext();

export const jhatpatPincodes = [411052, 411051, 411041, 411030, 411046];

export const QuickCommerceProvider = ({ children }) => {
  const initialProductType = sessionStorage.getItem("productType") || "Regular";
  const [quickCommerce, setQuickCommerce] = useState(initialProductType);
  const [addresses, setAddresses] = useState([]);
  const navigate = useNavigate();

  const jhatpatDeliveryEligible = addresses.find(({ pincode }) =>
    jhatpatPincodes.includes(pincode),
  );

  const refreshAddresses = async () => {
    const result = await axios.get(
      `${server}/address/user/${localStorage.getItem("user")}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );

    const addresses = result.data;
    setAddresses(result.data);
  };

  useEffect(() => {
    refreshAddresses();
  }, []);

  function updateProductType(productType) {
    // refreshAddresses();
    if (!localStorage.getItem("user")) {
      toast.error("Please log in");
      return navigate("/login");
    } else if (addresses.length === 0) {
      toast.error("No address added!");
      return navigate("/myaddress");
    } else if (productType === "QuickCommerce" && !jhatpatDeliveryEligible)
      toast.error("Your address is not in service area for झटपट डिलिव्हरी");
    else setQuickCommerce(productType);
  }

  useEffect(() => {
    sessionStorage.setItem("productType", quickCommerce);
  }, [quickCommerce]);

  useEffect(() => {
    if (quickCommerce == "QuickCommerce" && !jhatpatDeliveryEligible) {
      setQuickCommerce("Regular");
    }
  }, [addresses]);

  return (
    <QuickCommerceContext.Provider
      value={{
        quickCommerce,
        setQuickCommerce: updateProductType,
        refreshAddresses,
      }}
    >
      {children}
    </QuickCommerceContext.Provider>
  );
};

export const useQuickCommerce = () => useContext(QuickCommerceContext);
