import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import FiberPinOutlinedIcon from "@mui/icons-material/FiberPinOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import {
  Box,
  Button,
  InputAdornment,
  styled,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import server from "../../server";
import { useQuickCommerce } from "../context/QuickCommerceProvider";
import InvalidPincode from "../InvalidPincode/InvalidPincode";

const header = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  backgroundColor: "#fff",
  borderBottom: "1px solid #EAEAEA",
};

const arrowStyle = {
  position: "absolute",
  left: "20px",
};

const CategoryTitle = {
  fontWeight: "600",
  fontFamily: "Quicksand",
};

const InputPropsStyle = {
  sx: {
    fieldset: {
      borderRadius: 0,
      borderWidth: 0,
    },
    "&:focus-within fieldset, &:focus-visible fieldset": {
      border: "none !important",
    },
    input: {
      "&::placeholder": {
        color: "#868889 !important",
        fontSize: "16px",
        padding: "0px 0px 0px 10px",
        mixBlendMode: "normal",
        opacity: "0.87",
        fontWeight: "600",
        fontFamily: "quicksand",
      },
    },
  },
  startAdornment: (
    <InputAdornment position="start">
      <AccountCircleOutlinedIcon sx={{ color: "#969696" }} />
    </InputAdornment>
  ),
};

const InputPhoneStyle = {
  sx: {
    fieldset: {
      borderRadius: 0,
      borderWidth: 0,
    },
    "&:focus-within fieldset, &:focus-visible fieldset": {
      border: "none !important",
    },
    input: {
      "&::placeholder": {
        color: "#868889 !important",
        fontSize: "16px",
        padding: "0px 0px 0px 10px",
        mixBlendMode: "normal",
        opacity: "0.87",
        fontWeight: "600",
        fontFamily: "quicksand",
      },
    },
  },
  startAdornment: (
    <InputAdornment position="start">
      <LocalPhoneOutlinedIcon sx={{ color: "#969696" }} />
    </InputAdornment>
  ),
};

const InputLocationStyle = {
  sx: {
    fieldset: {
      borderRadius: 0,
      borderWidth: 0,
    },
    "&:focus-within fieldset, &:focus-visible fieldset": {
      border: "none !important",
    },
    input: {
      "&::placeholder": {
        color: "#868889 !important",
        fontSize: "16px",
        padding: "0px 0px 0px 10px",
        mixBlendMode: "normal",
        opacity: "0.87",
        fontWeight: "600",
        fontFamily: "quicksand",
      },
    },
  },
  startAdornment: (
    <InputAdornment position="start">
      <FmdGoodOutlinedIcon sx={{ color: "#969696" }} />
    </InputAdornment>
  ),
};

const InputLocationStyleAddress = {
  maxLength: 20,
  sx: {
    fieldset: {
      borderRadius: 0,
      borderWidth: 0,
    },
    "&:focus-within fieldset, &:focus-visible fieldset": {
      border: "none !important",
    },
    input: {
      "&::placeholder": {
        color: "#868889 !important",
        fontSize: "16px",
        padding: "0px 0px 0px 10px",
        mixBlendMode: "normal",
        opacity: "0.87",
        fontWeight: "600",
        fontFamily: "quicksand",
      },
    },
  },
  startAdornment: (
    <InputAdornment position="start">
      <FmdGoodOutlinedIcon sx={{ color: "#969696" }} />
    </InputAdornment>
  ),
};

const InputPincodeStyle = {
  sx: {
    fieldset: {
      borderRadius: 0,
      borderWidth: 0,
    },
    "&:focus-within fieldset, &:focus-visible fieldset": {
      border: "none !important",
    },
    input: {
      "&::placeholder": {
        color: "#868889 !important",
        fontSize: "16px",
        padding: "0px 0px 0px 10px",
        mixBlendMode: "normal",
        opacity: "0.87",
        fontWeight: "600",
        fontFamily: "quicksand",
      },
    },
  },
  startAdornment: (
    <InputAdornment position="start">
      <FiberPinOutlinedIcon sx={{ color: "#969696" }} />
    </InputAdornment>
  ),
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#6CC51D" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const switchText = {
  fontFamily: "quicksand",
  fontSize: "14px",
  fontWeight: "600",
};

const ButtonCart = {
  backgroundColor: "#5EC401",
  color: "#fff",
  textTransform: "none",
  padding: "10px",
  fontSize: "18px",
  fontWeight: "600",
  width: "100%",
  fontFamily: "quicksand",
  borderRadius: "8px",
  margin: "20px 0px 0px 0px",
  "&.MuiButtonBase-root:hover": {
    background: "#5EC401",
  },
  position: "relative",
};

const AddAddress = () => {
  const [name, setname] = useState("");
  const [mobile, setmobile] = useState("");
  const [locationType, setLocation] = useState("");
  const [addressline, setaddress] = useState("");
  const [pincode, setpincode] = useState("");
  const [status, setstatus] = useState();

  const [showModal, setModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const { quickCommerce, refreshAddresses } = useQuickCommerce();

  const changeName = async (name) => {
    const result = await axios.put(
      `${server}/user/update/${localStorage.getItem("user")}/${name}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
  };

  const addaddress = async (e) => {
    if (!name || !mobile || !locationType || !addressline || !pincode) {
      toast.error("Please fill all fields.");
      return;
    }

    const namePattern = /^[A-Za-z\s]+$/;
    if (!namePattern.test(name)) {
      toast.error("Name should contain only characters and no numbers.");
      return;
    }

    const mobileNumberPattern = /^[6-9]\d{9}$/;
    if (!mobileNumberPattern.test(mobile)) {
      toast.error("Please enter a valid 10-digit mobile number.");
      return;
    }

    if (userName === "Anonymous User") {
      changeName(name);
    }
    //validate pincode
    const checkaddress = await axios.get(
      `${server}/pincodevalidate/${pincode}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    // console.log(checkaddress)
    if (checkaddress.data === null) {
      setModal(true);
      return;
    }

    try {
      // const data = await axios.post(`${server}/auth/users/register`, {
      //   mobile: "+91" + mobile,
      //   pincode,
      //   name,
      // });
      // if (!data.data.message) {
      //   //new user
      //   // setUser(data.data.user);
      //   localStorage.setItem("user", data.data.user._id);
      //   localStorage.setItem("accessToken", data.data.accessToken);
      //   // setClient(true);
      // } else {
      //   //already registered
      //   // setUser(data.data.user);
      //   localStorage.setItem("user", data.data.user._id);
      //   localStorage.setItem("accessToken", data.data.accessToken);
      // }

      const result = await axios.post(
        `${server}/address`,
        {
          userId: localStorage.getItem("user"),
          recipientName: name,
          recipientPhoneNo: mobile,
          locationType: locationType,
          addressLine: addressline,
          pincode: pincode,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      refreshAddresses();
      navigate("/myaddress", { state: { status: status } });
    } catch (error) {
      console.log(error);
    }
  };

  const getName = async () => {
    const result = await axios.get(
      `${server}/users/${localStorage.getItem("user")}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    setUserName(result.data.name);
  };

  useEffect(() => {
    // if (!localStorage.getItem("user")) navigate("/");
    if (location.state) setstatus(location.state.status);
    getName();
  }, []);

  return (
    <Box>
      <Box sx={header}>
        <Box sx={arrowStyle}>
          <Link to="/myaddress">
            <ArrowBackRoundedIcon />
          </Link>
        </Box>
        <Typography variant="h6" sx={CategoryTitle}>
          Add Address
        </Typography>
      </Box>
      {showModal && <InvalidPincode modalstate={setModal} />}

      <Box
        sx={{
          padding: "10px 10px",
          backgroundColor: "#F4F5F9",
          height: "80vh",
        }}
      >
        <TextField
          placeholder="Full Name"
          onChange={(e) => setname(e.target.value)}
          sx={{
            backgroundColor: "#fff",
            borderRadius: "5px",
            width: "100%",
            margin: "4px 0px",
            border: "none",
          }}
          InputProps={InputPropsStyle}
          InputLabelProps={{
            sx: {
              color: "#868889",
              textTransform: "capitalize",
            },
          }}
        />

        <TextField
          placeholder="Phone number"
          onChange={(e) => setmobile(e.target.value)}
          sx={{
            backgroundColor: "#fff",
            borderRadius: "5px",
            width: "100%",
            margin: "4px 0px",
          }}
          InputProps={InputPhoneStyle}
          InputLabelProps={{
            sx: {
              color: "#868889",
              textTransform: "capitalize",
            },
          }}
        />

        <TextField
          placeholder="Location Type"
          onChange={(e) => setLocation(e.target.value)}
          sx={{
            backgroundColor: "#fff",
            borderRadius: "5px",
            width: "100%",
            margin: "4px 0px",
          }}
          InputProps={InputLocationStyle}
          InputLabelProps={{
            sx: {
              color: "#868889",
              textTransform: "capitalize",
            },
          }}
        />

        <TextField
          placeholder="Society, Flat, Street, Address"
          onChange={(e) => setaddress(e.target.value)}
          sx={{
            backgroundColor: "#fff",
            borderRadius: "5px",
            width: "100%",
            margin: "4px 0px",
          }}
          InputProps={InputLocationStyle}
          inputProps={{
            maxLength: 150,
          }}
          InputLabelProps={{
            sx: {
              color: "#868889",
              textTransform: "capitalize",
            },
          }}
        />

        <TextField
          placeholder="Pin-code"
          onChange={(e) => setpincode(e.target.value)}
          sx={{
            backgroundColor: "#fff",
            borderRadius: "5px",
            width: "100%",
            margin: "4px 0px",
          }}
          InputProps={InputPincodeStyle}
          InputLabelProps={{
            sx: {
              color: "#868889",
              textTransform: "capitalize",
            },
          }}
        />

        {/* <Box sx={{ marginTop: "30px" }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <AntSwitch
              defaultChecked
              inputProps={{ "aria-label": "ant design" }}
            />
            <Typography sx={switchText}>Save this address</Typography>
          </Stack>
        </Box> */}
        <Box
          sx={{
            position: "fixed",
            bottom: "100px",
            width: "95%",
          }}
        >
          <Button sx={ButtonCart} onClick={(e) => addaddress(e)}>
            Save Address
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddAddress;
