import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HashLoader } from "react-spinners";
import server from "../../server";
import {
  jhatpatPincodes,
  useQuickCommerce,
} from "../context/QuickCommerceProvider";
import AddressCard from "./Layout/AddressCard";

const header = {
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  padding: "20px",
  backgroundColor: "#fff",
  borderBottom: "1px solid #EAEAEA",
};

const arrowStyle = {
  margin: "0px 20px 0px 0px",
};

const CategoryTitle = {
  fontWeight: "600",
  fontFamily: "Poppins",
  fontSize: "20px",
  color: "#37474F",
};

const ButtonCart = {
  backgroundColor: "#5EC401",
  color: "#fff",
  textTransform: "none",
  padding: "10px",
  fontSize: "16px",
  fontWeight: "500",
  width: "100%",
  fontFamily: "Poppins",
  borderRadius: "8px",
  margin: "20px 0px 0px 0px",
  "&.MuiButtonBase-root:hover": {
    background: "#5EC401",
  },
  position: "relative",
};

const buttonIcon = { position: "absolute", right: "20px" };
function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const MyAddress = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [addressses, setAddressess] = useState([]);
  const [status, setstatus] = useState();
  const [returnid, setreturn] = useState();
  const { quickCommerce, setQuickCommerce, refreshAddresses } =
    useQuickCommerce();

  const [loading, setLoading] = useState(false);

  const getAllAddresses = async () => {
    if (location.state) setstatus(location.state.status);
    if (location.state) setreturn(location.state.return);
    const result = await axios.get(
      `${server}/address/user/${localStorage.getItem("user")}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    const addresses = result.data;
    setAddressess(addresses);
    refreshAddresses();
  };

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    else getAllAddresses();
  }, []);

  useEffect(() => {}, [addressses, status, returnid]);
  const handleChange = async (event) => {
    setLoading(true);

    const isChecked = event.target.checked;
    if (isChecked) setQuickCommerce("QuickCommerce");
    else setQuickCommerce("Regular");

    await delay(800);
    setLoading(false);
  };
  const servicableAddresses =
    quickCommerce === "QuickCommerce"
      ? addressses.filter((a) => jhatpatPincodes.includes(a.pincode))
      : addressses
        ? addressses
        : [];
  return (
    <>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
          }}
        >
          <HashLoader color="#5EC401" size={100} />
        </div>
      )}
      <Box sx={header}>
        <Box sx={arrowStyle}>
          <Link to="/profile">
            <ArrowBackRoundedIcon />
          </Link>
        </Box>
        <Typography variant="h6" sx={CategoryTitle}>
          Addresses
        </Typography>
        <div className="flex gap-4 w-full justify-end items-center">
          <p>झटपट डिलिव्हरी</p>
          <FormControlLabel
            control={
              <Switch
                checked={quickCommerce === "QuickCommerce"}
                onChange={handleChange}
              />
            }
            sx={{ marginRight: 0, padding: 0 }}
          />
        </div>
      </Box>
      <Box sx={{ padding: "0px 20px" }}>
        {addressses.map((curraddress) => {
          return (
            <AddressCard
              addressdetail={curraddress}
              refresh={getAllAddresses}
              key={curraddress._id}
              status={status}
              return={returnid}
              jhatpatPincodes={jhatpatPincodes}
              quickCommerce={quickCommerce}
            />
          );
        })}
      </Box>
      <Box
        sx={{
          padding: "0px 10px",
          position: "fixed",
          bottom: "100px",
          width: "94%",
        }}
      >
        {/* <Link to="/addaddress" > */}
        <Button
          sx={ButtonCart}
          onClick={(e) => {
            e.preventDefault();
            navigate("/addaddress", { state: { status: status } });
          }}
        >
          <AddRoundedIcon sx={buttonIcon} /> Add New Address
        </Button>
        {/* </Link> */}
      </Box>
    </>
  );
};

export default MyAddress;
