import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {
  Box,
  Button,
  IconButton,
  InputBase,
  Skeleton,
  styled,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as Cart } from "../../../../assets/cart.svg";
import DefaultImage from "../../../../assets/defaultimg.jpg";
import { weightvariants } from "../../../../assets/variants";
import { topRightToastStyles } from "../../../../constants/styles";
import { fetchData } from "../../../../redux/slices/dataSlice";
import server from "../../../server";
import { useQuickCommerce } from "../../context/QuickCommerceProvider";
import ProductCard from "../../Home/Layout/ProductCard";
import { NavBarContext } from "../../Navbar/Navbar";
import ReviewCard from "./ReviewCard";

const ProductTitleDiv = {
  padding: "10px ",
  backgroundColor: "#F1F1F1",
  border: "1px solid #E3E3E3",
};

const InventoryInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    color: "#000000",
    marginTop: "10px",
  },
  "& .MuiInputBase-input": {
    color: "#808080",
    backgroundColor: "#ffffff",
    borderRadius: "3px",
    border: "1px solid #E0E0E0",
    fontSize: "12px",
    padding: "0px 5px",
  },
}));

const ProductDescTitle = {
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: "500",
};

const ProductPrice = {
  color: "#F37A20",
  fontWeight: "600",
  fontFamily: "quicksand",
  fontSize: "24px",
  margin: "0px 8px",
  //   textDecoration: "line-through",
};

const ProductSalePrice = {
  color: "#ADADAD",
  textDecoration: "line-through",
  fontSize: "15px",
  fontFamily: "quicksand",
  fontWeight: 500,
};

const SalePercentageText = {
  backgroundColor: "#FF4F4F",
  color: "#fff",
  padding: "3px 10px",
  borderRadius: "3px",
  fontSize: "14px",
  fontWeight: "400",

  fontFamily: "quicksand",
};

const buttonPlusMinus = {
  padding: "8px 18px",
  backgroundColor: "#5EC401",
  color: "#fff",
};

const ProductImage = {
  position: "relative",
  //   margin: "20px 20px 0px 20px",
  padding: "20px",
  borderBottom: "1px solid #EAEAEA",
};

const AvailableSize = {
  padding: "10px 15px",
};

const AvailableSizeText = {
  fontSize: "16px",
  fontFamily: "quicksand",
  fontWeight: "600",
  margin: "0px 0px 10px 0px",
};

const AvailableSizeDiv = {
  backgroundColor: "#fff",
  border: "1px solid #EAEAEA",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  margin: "0px 0px 10px 0px",
  padding: "3px 0px",
  borderRadius: "5px",
};

const AvailableSizeLiter = {
  fontWeight: "600",
  fontFamily: "quicksand",
};

const AvailableSaleCard = {
  backgroundColor: "#F37A20",
  color: "#fff",
  padding: "3px 10px",
  borderRadius: "3px",
  fontSize: "12px",
  fontWeight: "400",
  fontFamily: "quicksand",
};

const DeliverDiv = {
  padding: "10px ",
  borderTop: "8px solid #F2F2F2",
  borderBottom: "8px solid #F2F2F2",
};

const DeliverText = {
  fontSize: "16px",
  fontFamily: "quicksand",
  fontWeight: "600",
};

const ViewCategoryText = {
  fontWeight: "600",
  fontFamily: "Quicksand",
  fontSize: "15px",
};

const ButtonCart = {
  backgroundColor: "#5EC401",
  position: "relative",
  display: "flex",
  justifyContent: "space-between",
  // paddingRight:"100px",
  // marginTop:"100%",
  // bottom:0,
  // zindex
  // margin:"10px",
  color: "#fff",
  textTransform: "none",
  padding: "10px 10px",
  fontSize: "18px",
  fontWeight: "600",
  width: "90%",
  "&.MuiButtonBase-root:hover": {
    background: "#64cf00",
  },
  "&.Mui-disabled": {
    // background: "#eaeaea",
    color: "#fff",
  },
};

const ProductDesc = (props) => {
  const { setItems, wishList, fetchWishList } = useContext(NavBarContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [productinfo, setProductInfo] = useState([]);
  const [variants, setVariants] = useState([]);
  const [properties, setProperties] = useState([]);
  const [quantitybutton, setQuantityButton] = useState(false);
  const [quantity, setQuantity] = useState();
  //this state is to store the value of variant that user selected to purchase
  const [selectedVariant, setSelectedVariant] = useState(1);
  const [similarproducts, setSimilarProducts] = useState([]);
  const [cartdetails, setCartDetails] = useState([]);
  const { quickCommerce } = useQuickCommerce();

  const [wishlistdetails, setwishlistdetails] = useState([]);
  const [wishstatus, setWishStatus] = useState(
    wishList.includes(props.productid),
  );

  useEffect(() => {
    setWishStatus(wishList.includes(props.productid));
  }, [wishList]);

  const [weights, setweights] = useState(weightvariants.weights);
  const [currentsubcat, setsubcat] = useState([]);

  const fetchproductinfo = async () => {
    try {
      const result = await axios.get(
        `${server}/public/products/${props.productid}?productType=${quickCommerce}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      setProductInfo(result.data.product);
      //   console.log(result.data);
      const subcatresult = await axios.get(
        `${server}/public/subcategory/find/${result.data.product.categoryId?._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      setsubcat(subcatresult.data);
      //   console.log(subcatresult.data);
      // props.getcategory(result.data.product.categoryId)
      if (result.data.variants) {
        setVariants(result.data.variants);
        // setProperties(result.data.variants[0].properties.weight)
        result.data.variants.forEach((variant) =>
          setProperties([...properties, variant.properties]),
        );
      }

      const similarresults = await axios.get(
        `${server}/public/products/byCategoryId/${result.data.product.categoryId?._id}?productType=${quickCommerce}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      setSimilarProducts(similarresults.data);
      // console.log(similarresults)
    } catch (error) {
      console.log(error.message);
    }
  };

  const changeSelectedVariant = (e) => {
    e.preventDefault();

    let cartarr = JSON.parse(localStorage.getItem("cart"));

    variants.map((currvariant, i) => {
      if (currvariant.properties[0].weight === e.target.value) {
        //searching for the quantity of new selected variant
        const exists = cartarr.some(function (element) {
          if (element.length === 3) {
            if (element[0] == variants[i]._id) {
              console.log("found already existing variant");
              if (element[1] !== "0") {
                setQuantity(parseInt(element[1]));
                setQuantityButton(true);
                return true;
              } else setQuantityButton(false);
            }
          }
          // else setQuantityButton(false)
        });
        if (!exists) setQuantityButton(false);

        setSelectedVariant(i);
        return;
      }
    });
  };

  const addtocart = async (e) => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate("/login");
    }
    e.preventDefault();

    //using JSON of weights as given on 22 july
    let cartarr = JSON.parse(localStorage.getItem("cart"));
    if (localStorage.getItem("cart")) {
      //check for prexisting product
      const exists = cartarr.some(function (element) {
        if (element[0] == props.productid) {
          if (element.length === 3) {
            element[2] = (parseInt(element[2]) + 1).toString();
            if (selectedVariant >= 100) {
              // divide by 1000
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) +
                  productinfo.price * (selectedVariant / 1000),
              );
            } else
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) +
                  productinfo.price * selectedVariant,
              );

            element[1] = selectedVariant;
          } else {
            element[1] = (parseInt(element[1]) + 1).toString();

            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) + productinfo.price,
            );
          }
          // console.log(element[1])
          setQuantityButton(true);
          setQuantity(1);
          setItems((current) => current + 1);

          return true;
        }
      });
      //increment the quantity

      //update the cart
      if (!exists) {
        //check if it is prod or variant
        let temparr;
        if (productinfo?.subCategoryId?.name?.includes("Loose")) {
          temparr = [props.productid, selectedVariant, 1];
          if (selectedVariant >= 100) {
            // divide by 1000
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) +
                productinfo.price * (selectedVariant / 1000),
            );
          } else
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) +
                productinfo.price * selectedVariant,
            );
        } else {
          temparr = [props.productid, 1];
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) + productinfo.price,
          );
        }

        cartarr.push(temparr);
        setQuantity(1);
        setQuantityButton(true);
        setItems((current) => current + 1);
      }
    }

    localStorage.setItem("cart", JSON.stringify(cartarr));
    dispatch(fetchData())
      .unwrap()
      .then((data) => {
        console.log("Data fetched successfully:", data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    //latest code ends here from 22 july

    toast.success("Added to bag. ", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const changequantity = (e, i) => {
    e.preventDefault();

    console.log("quant change");
    let cartarr = JSON.parse(localStorage.getItem("cart"));
    if (i == 1) {
      //plus button
      // props.proddetail[1] = 6
      if (
        productinfo.buyinglimit === quantity &&
        productinfo.buyinglimit !== 0
      ) {
        toast.warning("Buying Limit Exceeded", topRightToastStyles);
        return;
      }
      setQuantity((current) => current + 1);
      //change in localstorage also

      //check if variant or product FIRST then proceed
      //it is a product not a variant
      const exists = cartarr.some(function (element) {
        if (element[0] == props.productid) {
          if (element.length === 3) {
            element[2] = (parseInt(element[2]) + 1).toString();
            if (selectedVariant >= 100) {
              // divide by 1000
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) +
                  productinfo.price * (selectedVariant / 1000),
              );
            } else
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) +
                  productinfo.price * selectedVariant,
              );
          } else {
            element[1] = (parseInt(element[1]) + 1).toString();
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) + productinfo.price,
            );
          }

          return true;
        }
      });

      // toast.success("Quantity Increased", {
      //   position: "top-right",
      //   autoClose: 80,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    } else {
      setQuantity((current) => current - 1);

      //it is a product not a variant
      const exists = cartarr.some(function (element) {
        if (element[0] == props.productid) {
          if (element.length === 3) {
            element[2] = (parseInt(element[2]) - 1).toString();
            if (selectedVariant >= 100) {
              // divide by 1000
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) -
                  productinfo.price * (selectedVariant / 1000),
              );
            } else
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) -
                  productinfo.price * selectedVariant,
              );

            if (element[2] == 0) {
              //remove from cart
              setQuantityButton(false);
              setItems((current) => current - 1);
              return false;
            }
            return true;
          } else {
            element[1] = (parseInt(element[1]) - 1).toString();
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) - productinfo.price,
            );
            if (element[1] == 0) {
              //remove from cart
              setQuantityButton(false);
              setItems((current) => current - 1);
              return false;
            }
            return true;
          }
        }
      });

      // toast.success("Quantity Decreased", {
      //   position: "top-right",
      //   autoClose: 80,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
    localStorage.setItem("cart", JSON.stringify(cartarr));
  };
  //use this function in future if cart model is used

  const changestatus = async (e) => {
    e.preventDefault();

    if (wishstatus) {
      //call delete wishlist axios call
      const result = await axios.post(
        `${server}/user/wishlist/delete`,
        {
          userId: localStorage.getItem("user"),
          productId: props.productid,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      // console.log(result)
    } else {
      //call add wishlist axios call
      const result = await axios.post(
        `${server}/user/wishlist/add`,
        {
          userId: localStorage.getItem("user"),
          productId: props.productid,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      // console.log(result)
      toast.success("added to WishList", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    await fetchWishList();
    // setwish(!wishstatus);
  };

  const displaybutton = () => {
    let cartarr = JSON.parse(localStorage.getItem("cart"));
    setQuantityButton(false);

    //july 22 code below
    cartarr.some(function (element) {
      if (element[0] == props.productid) {
        if (element.length == 3) {
          if (parseInt(element[2]) !== 0) {
            console.log(element);
            setQuantityButton(true);
            setQuantity(parseInt(element[2]));
            setSelectedVariant(element[1]);
            // console.log("changed variant")
          }
        } else {
          if (parseInt(element[1]) !== 0) {
            setQuantityButton(true);
            setQuantity(parseInt(element[1]));
          }
        }
        // console.log('changing button' + element[1])
      }
      // else setQuantityButton(false)
    });
  };

  const handleChange = (e) => {
    e.preventDefault();

    //meaning old variant has some non zero quantity in cart
    //turn it to zero first
    let cartarr = JSON.parse(localStorage.getItem("cart"));

    //july 22 code below
    const exists = cartarr.some(function (element) {
      if (element[0] == props.productid) {
        if (element[1] >= 100) {
          // divide by 1000
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) -
              productinfo.price * (element[1] / 1000) * element[2],
          );
        } else
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) -
              productinfo.price * element[1] * element[2],
          );

        element[1] = e.target.value;
        if (element[1] >= 100) {
          // divide by 1000
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) +
              productinfo.price * (element[1] / 1000) * element[2],
          );
        } else
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) +
              productinfo.price * element[1] * element[2],
          );

        return true;
      }

      // else setQuantityButton(false)
    });

    localStorage.setItem("cart", JSON.stringify(cartarr));

    setSelectedVariant(e.target.value);
    setQuantity(1);
  };

  useEffect(() => {
    // getwishes();
    fetchWishList();
    displaybutton();
  }, []);

  useEffect(() => {
    fetchproductinfo();
    // getwishes();
    displaybutton();
  }, [props.productid]);

  const looseItemLimit =
    Math.min(
      productinfo.stock - (productinfo.stocklimit || 0),
      productinfo.buyinglimit || Infinity,
    ) <
    (productinfo?.subCategoryId?.name?.includes("Loose")
      ? weightvariants.factors[
          weightvariants.weights.indexOf(parseInt(selectedVariant))
        ]
      : 1) *
      (quantity + 1);

  const unavailableOption = (currweight) => {
    const index = weightvariants.weights.indexOf(parseInt(currweight));
    return (
      Math.min(
        productinfo.stock - (productinfo.stocklimit || 0),
        productinfo.buyinglimit || Infinity,
      ) < weightvariants.factors[index]
    );
  };

  return (
    <Box sx={{ marginBottom: "200px" }}>
      <Box sx={ProductTitleDiv}>
        <Typography sx={ProductDescTitle}>{productinfo.name}</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {productinfo.length !== 0 ? (
              productinfo?.subCategoryId?.name?.includes("Loose") ? (
                selectedVariant >= 100 ? (
                  <Typography sx={ProductPrice}>
                    ₹
                    {(productinfo.price * (selectedVariant / 1000))?.toFixed(2)}
                  </Typography>
                ) : (
                  <Typography sx={ProductPrice}>
                    ₹{(productinfo.price * selectedVariant)?.toFixed(2)}
                  </Typography>
                )
              ) : (
                <>
                  <Typography sx={ProductSalePrice}>
                    ₹{productinfo.mrpPrice?.toFixed(2)}
                  </Typography>
                  <Typography sx={ProductPrice}>
                    ₹{productinfo.price?.toFixed(2)}
                  </Typography>
                </>
              )
            ) : (
              <></>
            )}
          </Box>

          {/* {100 -
						parseInt(productinfo.price / productinfo.mrpPrice) *
							100 >
					0 ? (
						<Typography sx={SalePercentageText}>
							Save{" "}
							{100 -
								parseInt(
									(productinfo.price / productinfo.mrpPrice) *
										100
								)}{" "}
							%OFF
						</Typography>
					) : (
						<></>
					)} */}
          {parseInt(productinfo.mrpPrice - productinfo.price) > 0 ? (
            <Typography sx={SalePercentageText}>
              Save ₹{parseInt(productinfo.mrpPrice - productinfo.price)}
            </Typography>
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <Box sx={ProductImage}>
        {productinfo.length == 0 ? (
          <Skeleton
            variant="rectangular"
            className="product-desc-image"
            width={210}
            height={118}
          />
        ) : (
          <img
            src={
              productinfo.imageUrl === "noimage"
                ? DefaultImage
                : productinfo.imageUrl
            }
            alt=""
            className="product-desc-image"
          />
        )}
        <Button
          onClick={changestatus}
          style={{ position: "absolute", top: "0", right: "0" }}
        >
          {wishstatus ? (
            <FavoriteIcon
              sx={{
                color: "red",
              }}
            />
          ) : (
            <FavoriteBorderIcon
              style={{
                color: "red",
              }}
            />
          )}
        </Button>
      </Box>

      {productinfo.length !== 0 ? (
        productinfo?.subCategoryId?.name?.includes("Loose") ? (
          <FormControl size="small" sx={{ margin: "10px 0px" }}>
            <Typography
              sx={{
                fontFaimly: "quicksand",
                fontSize: "16px",
                fontWeight: 600,
                padding: "10px ",
                // width: "100%"
              }}
            >
              Choose from available sizes
            </Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              defaultValue={selectedVariant}
              onChange={(e) => handleChange(e)}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                  // overflow: "scroll"
                }}
              >
                {weights &&
                  weights
                    .filter((w) => !unavailableOption(w))
                    .map((currweight, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            width: "40%",
                            border: "1px solid #EAEAEA",
                            padding: "5px",
                            marginBottom: "5px",
                            // maxHeight:"10vh"
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "quicksand",
                                fontWeight: 600,
                                fontSize: "14px",
                              }}
                            >
                              {" "}
                              {currweight >= 100 ? (
                                <>{currweight}gm</>
                              ) : (
                                <>{currweight}kg</>
                              )}{" "}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "quicksand",
                                  fontWeight: 600,
                                  fontSize: "14px",
                                  color: "rgba(243, 122, 32, 1)",
                                }}
                              >
                                ₹{" "}
                                {currweight >= 100 ? (
                                  <>
                                    {" "}
                                    {(
                                      productinfo?.price *
                                      (currweight / 1000)
                                    )?.toFixed(2)}{" "}
                                  </>
                                ) : (
                                  <>{productinfo?.price * currweight}</>
                                )}
                              </Typography>
                              <FormControlLabel
                                value={currweight}
                                control={<Radio />}
                                sx={{
                                  margin: "0px",
                                }}
                              />
                            </Box>
                          </Box>
                        </div>
                      );
                    })}
                <Box sx={{ width: "40%", padding: "5px" }}></Box>
              </Box>
            </RadioGroup>
            {/* <Select
            sx={{ width: "100vw" }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
            label="inventory" input={<InventoryInput />} defaultValue={selectedVariant} onChange={(e) => handleChange(e)}>
            {
              weights && weights.map((currweight, i) => {
                return <MenuItem value={currweight} key={i} name={i} >
                  {currweight >= 100 ? <>{currweight}gm</>
                    : <>{currweight}kg</>
                  }
                </MenuItem>
              })
            }
          </Select> */}
          </FormControl>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      <Box sx={DeliverDiv}>
        <Typography sx={DeliverText}>
          Get it delivered to your doorstep in 48-72 hours
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <Typography variant="subtitle1" sx={ViewCategoryText}>
          Similar Products
        </Typography>
        {currentsubcat.length > 0 ? (
          <Link
            // to={`/categoryproducts/${currentsubcat[0].name}/${currentsubcat[0]._id}/${productinfo.categoryId?._id}`}
            to={`/categoryproducts/${currentsubcat[0].name}/${currentsubcat[0].name}}`}
            state={{
              categoryId: currentsubcat[0].categoryId._id,
              subcategoryId: currentsubcat[0]._id,
              name: currentsubcat[0].name,
            }}
            // "/categoryproducts/:name/:subcatname"
            // categoryId, subcategoryId, name
            // state={{
            //   { categoryId:,
            //      subcategoryId:,
            //       name: }
            // }}

            // to={`/subcategoryPage/${currcategory.name}`}
            // state={{
            //   catid: currcategory._id,
            //   categoryName: currcategory.name,
            // }}
            style={{ textDecoration: "none" }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "#407EC7",
                fontFamily: "poppins",
                fontWeight: 500,
                fontSize: "12px",
              }}
            >
              View all
            </Typography>
          </Link>
        ) : (
          <></>
        )}
        {/* 
        <Typography variant="subtitle2" sx={{ color: "#407EC7" }}>
          View all
        </Typography> */}
      </Box>

      <Box
        sx={{
          display: "flex",
          overflowY: "scroll",
          width: "100%",
          height: "100%",
          backgroundColor: "#F2F2F2",
        }}
      >
        {similarproducts &&
          similarproducts.map((currsimilarprod, i) => {
            return (
              <>
                {i > 4 ? (
                  <></>
                ) : currsimilarprod.published ? (
                  <Box sx={{ margin: "10px 10px" }} key={i}>
                    <ProductCard proddetails={currsimilarprod} />
                  </Box>
                ) : (
                  <></>
                )}
              </>
            );
          })}
      </Box>
      <ReviewCard prodid={props.productid} />

      <Box
        sx={{
          padding: "0px 0px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {quantitybutton ? (
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              // left: "50vw",
              backgroundColor: "#fff",
              borderRadius: "7px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              zIndex: 10,
              width: "95%",
              // padding: "10px",
              marginBottom: "100px",
              mx: "5%",
            }}
          >
            <Typography
              sx={{
                padding: "10px",
                fontFamily: "quicksand",
                fontWeight: 600,
                fontSize: "15px",
              }}
            >
              Product added to cart!
            </Typography>
            <Box
              sx={{
                backgroundColor: "#5EC401",
                borderRadius: "7px",
                color: "#fff",
                // width:"100%",
                height: "100%",
              }}
            >
              <IconButton
                sx={buttonPlusMinus}
                onClick={(e) => changequantity(e, 0)}
              >
                -
              </IconButton>
              {quantity}
              <IconButton
                sx={buttonPlusMinus}
                onClick={(e) => changequantity(e, 1)}
                disabled={looseItemLimit}
              >
                +
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              // left: "50vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 10,
              width: "100%",
              marginBottom: "100px",
            }}
          >
            <Button
              sx={ButtonCart}
              onClick={(e) => addtocart(e)}
              disabled={
                productinfo.stocklimit &&
                productinfo.stocklimit > productinfo.stock
                  ? true
                  : false
              }
            >
              <Box></Box>
              <Typography
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >
                {productinfo.stocklimit &&
                productinfo.stocklimit > productinfo.stock ? (
                  <>Out Of Stock</>
                ) : (
                  <>Add to cart</>
                )}
              </Typography>
              <Cart
                style={{
                  margin: "0px 5px",
                  height: "auto",
                  width: "18px",
                }}
              />
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProductDesc;
